define('modules/settings/shop/tabs/SystemMessagesTabController',[], function () {
	'use strict';

	return function SystemMessagesController(
		$parentScope,
		$scope,
		{ BASE_TEMPLATES_PATH, NotificationService, SystemMessageUnpagedService }
	) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.SYSTEM_MESSAGES',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-system-messages.html',
		});

		$parentScope.selected.systemMessageId = 0;

		SystemMessageUnpagedService.findAll().then(function () {
			$scope.systemMessages = SystemMessageUnpagedService.getAll(true).data;
		});

		$scope.addSystemMessage = function () {
			if (!$parentScope.entity.systemMessageIds) {
				$parentScope.entity.systemMessageIds = [];
			}
			if (!$parentScope.entity.systemMessageIds.includes($parentScope.selected.systemMessageId))
				$parentScope.entity.systemMessageIds.push($parentScope.selected.systemMessageId);
			else {
				NotificationService.notifyGeneralError({ statusText: 'System Message is already selected!' });
			}
		};

		$scope.removeSystemMessages = function (index) {
			$parentScope.entity.systemMessageIds.splice(index, 1);
		};

		$scope.systemMessageChanged = function () {
			$parentScope.defaultValueChanged(
				$scope.systemMessages,
				$parentScope.selectedCurrencies,
				$parentScope.entity.defaultCurrencyId
			);
		};
	};
});

