/**
 * Created by nmayer on 24.05.16.
 */
define('services/RuleService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('RuleService', ['$injector', '$q', 'InstallationService', RuleService]);
});

function RuleService($injector, $q, InstallationService) {
	this.groupConjunctions = function () {
		return [
			{
				value: 'AND',
				label: 'RULE.CONJUNCTIONS.AND',
			},
			{
				value: 'OR',
				label: 'RULE.CONJUNCTIONS.OR',
			},
			{
				value: 'XOR',
				label: 'RULE.CONJUNCTIONS.XOR',
			},
		];
	};

	this.conditionConjunctions = function () {
		return [
			{
				value: 'ALL_TRUE',
				label: 'RULE.CONJUNCTIONS.ALL_TRUE',
			},
			{
				value: 'ALL_FALSE',
				label: 'RULE.CONJUNCTIONS.ALL_FALSE',
			},
		];
	};

	this.conditionTypes = function () {
		let ref = $q.defer();
		const conditions = [];

		// This is stupid, we need to figure something out
		InstallationService.requestPlugins(() => {
			if (InstallationService.isPluginInstalled('7710854808841553559')) {
				conditions.push({
					name: 'RULE.CONDITION_TYPES.OrderIncludesDigitalProductsCondition.TYPE',
					value: 'OrderIncludesDigitalProductsCondition',
				});
				conditions.push({
					name: 'RULE.CONDITION_TYPES.OrderConsistsOnlyOfDigitalProductsCondition.TYPE',
					value: 'OrderConsistsOnlyOfDigitalProductsCondition',
				});
			}
			if (InstallationService.isPluginInstalled('5621785307235780252')) {
				conditions.push({
					name: 'RULE.CONDITION_TYPES.RequestedByTerminalCondition.TYPE',
					value: 'RequestedByTerminalCondition',
				});
			}

			conditions.push(
				{
					name: 'Width Greater Than',
					value: 'ShopShippingConditionWidthGreaterThan',
				},
				{
					name: 'Specific Products With Amount',
					value: 'ShopShippingConditionSpecificItemsAmountCheck',
				},
				{
					name: 'Product with specific metadata value',
					value: 'OrderProductHasMetadataNameValueShippingCondition',
				},
				{
					name: 'Exclusively products with specific metadata value',
					value: 'OrderExclusivelyHasProductsWithMetadataNameValueShippingCondition',
				},
				{
					name: 'Customer is a verified business',
					value: 'CustomerIsVerifiedBusinessCondition',
				},
				{
					name: 'Order with specific shipping method',
					value: 'ShippingMethodCondition',
				}
			);

			ref.resolve(conditions);
		});

		return ref.promise;
	};

	return this;
}
;
