define('modules/settings/shop/tabs/ShippingMethodTabController',[], function () {
	'use strict';

	return function ShippingMethodController(
		$parentScope,
		$scope,
		{
			BASE_TEMPLATES_PATH,
			ShippingMethodService,
			CountryService,
			CountryUnpagedService,
			ShopService,
			NotificationService,
			NavigationService,
			UnitService,
			RuleService,
			MetadataService,
		}
	) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.SHIPPING_METHOD',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-shipping-methods.html',
			service: ShippingMethodService,
		});

		$scope.deliveryMethods = [
			{
				name: 'Land',
				value: 'DESTINATION',
			},
			{
				name: 'Gewicht',
				value: 'SHOPPINGCARTWEIGHT',
			},
			{
				name: 'Volumen',
				value: 'SHOPPINGCARTVOLUME',
			},
			{
				name: 'Gewicht & Volumen',
				value: 'SHOPPINGCARTWEIGHTVOLUME',
			},
		];

		$parentScope.selectedCountries = [];
		$scope.countriesTableOptions = {};
		$scope.countriesTableOptions.fields = [
			{
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				heading: 'FIELDS.ISO',
				attribute: 'countryIso2',
			},
		];

		$scope.showAddShipping = false;
		$scope.showCreateShopShipping = false;
		$scope.showAddShippingButton = true;

		$scope.newShippingMethod = {
			name: '',
			description: '',
		};

		$scope.conditionPath = BASE_TEMPLATES_PATH + `/settings/shop/shippingPolicyConditions/`;
		RuleService.conditionTypes().then((conditions) => {
			$scope.conditionTypes = conditions;
		});

		$scope.ShippingActionTypes = [
			{
				name: 'Select Shipping Method',
				value: 'ShopShippingActionSelectSpecificShippingMethod',
			},
		];

		$scope.country = {};

		$scope.init = () => {
			CountryService.findAll().then(function () {
				$scope.countries = CountryService.getAll(true);
				$parentScope.selected.countriesTotalItems = CountryService.getTotalItems();
				$parentScope.selected.countriesPageSize = CountryService.getPageSize();
				$scope.markSelectedCountries();
			});

			CountryUnpagedService.findAll().then(function () {
				$scope.allCountries = CountryUnpagedService.getAll(true).data;
				$scope.selectCountries = angular.copy($scope.allCountries);
			});

			if (!!$parentScope.entity.shippingRules) {
				for (let rule of $parentScope.entity.shippingRules) {
					rule.position = rule.sortOrder ? rule.sortOrder : rule.position;
				}
			}
		};

		$scope.beforeSave = () => {
			for (let i = 0; i < $parentScope.entity.shopShippingMethods.length; i++) {
				if ($parentScope.entity.shopShippingMethods[i].tax === undefined) {
					$parentScope.entity.shopShippingMethods[i].tax = {};
				}
				$parentScope.entity.shopShippingMethods[i].tax.id = $parentScope.entity.shopShippingMethods[0].tax.id;

				for (let j = 0; j < $parentScope.entity.shopShippingMethods[i].deliveryServices.length; j++) {
					if ($parentScope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod != undefined) {
						if (
							$parentScope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
								.deliveryMethodType != undefined
						) {
							if (
								$parentScope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
									.deliveryMethodType == 'DESTINATION'
							) {
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.deliveryMethodConfigurations = [];
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.deliveryMethodConfigurations =
									$parentScope.entity.shopShippingMethods[i].deliveryServices[
										j
									].deliveryMethod.countryConfigurations;
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.countryConfigurations = [];
							}

							if (
								$parentScope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
									.deliveryMethodType == 'SHOPPINGCARTWEIGHT'
							) {
								let basicConfig = [];
								let count = 0;
								if ($parentScope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs) {
									for (
										let l = 0;
										l <
										$parentScope.entity.shopShippingMethods[i].deliveryServices[j].weightConfigs
											.length;
										l++
									) {
										for (
											let m = 0;
											m <
											$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
												.weightConfigs[l].countries.length;
											m++
										) {
											for (
												let n = 0;
												n <
												$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
													.weightConfigs[l].configurations.length;
												n++
											) {
												basicConfig[count] = angular.copy(
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.weightConfigs[l].configurations[n]
												);
												basicConfig[count].groupId =
													$parentScope.entity.shopShippingMethods[i].deliveryServices[
														j
													].weightConfigs[l].groupId;
												basicConfig[count].valueId = n + 1;
												basicConfig[count].country = $scope.getCountryById(
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.weightConfigs[l].countries[m]
												);
												if (
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.weightConfigs[l].configurations[n].weightUnit != undefined
												) {
													basicConfig[count].weightUnit = $scope.getUnitById(
														$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
															.weightConfigs[l].configurations[n].weightUnit.id
													);
												}
												count++;
											}
										}
									}
								}
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.deliveryMethodConfigurations = [];
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.deliveryMethodConfigurations = basicConfig;
							}

							if (
								$parentScope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
									.deliveryMethodType == 'SHOPPINGCARTVOLUME'
							) {
								let basicConfig = [];
								let count = 0;
								if ($parentScope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs) {
									for (
										let l = 0;
										l <
										$parentScope.entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs
											.length;
										l++
									) {
										for (
											let m = 0;
											m <
											$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
												.volumeConfigs[l].countries.length;
											m++
										) {
											for (
												let n = 0;
												n <
												$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
													.volumeConfigs[l].configurations.length;
												n++
											) {
												basicConfig[count] = angular.copy(
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.volumeConfigs[l].configurations[n]
												);
												basicConfig[count].groupId =
													$parentScope.entity.shopShippingMethods[i].deliveryServices[
														j
													].volumeConfigs[l].groupId;
												basicConfig[count].valueId = n + 1;
												basicConfig[count].country = $scope.getCountryById(
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.volumeConfigs[l].countries[m]
												);
												if (
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.volumeConfigs[l].configurations[n].volumeUnit != undefined
												) {
													basicConfig[count].volumeUnit = $scope.getUnitById(
														$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
															.volumeConfigs[l].configurations[n].volumeUnit.id
													);
												}
												count++;
											}
										}
									}
								}
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.deliveryMethodConfigurations = [];
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.deliveryMethodConfigurations = basicConfig;
							}

							if (
								$parentScope.entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
									.deliveryMethodType == 'SHOPPINGCARTWEIGHTVOLUME'
							) {
								let basicConfig = [];
								let count = 0;
								if (
									$parentScope.entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs
								) {
									for (
										let l = 0;
										l <
										$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
											.weightVolumeConfigs.length;
										l++
									) {
										for (
											let m = 0;
											m <
											$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
												.weightVolumeConfigs[l].countries.length;
											m++
										) {
											for (
												let n = 0;
												n <
												$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
													.weightVolumeConfigs[l].configurations.length;
												n++
											) {
												basicConfig[count] = angular.copy(
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.weightVolumeConfigs[l].configurations[n]
												);
												basicConfig[count].groupId =
													$parentScope.entity.shopShippingMethods[i].deliveryServices[
														j
													].weightVolumeConfigs[l].groupId;
												basicConfig[count].valueId = n + 1;
												basicConfig[count].country = $scope.getCountryById(
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.weightVolumeConfigs[l].countries[m]
												);
												if (
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.weightVolumeConfigs[l].configurations[n].weightUnit !=
													undefined
												) {
													basicConfig[count].weightUnit = $scope.getUnitById(
														$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
															.weightVolumeConfigs[l].configurations[n].weightUnit.id
													);
												}
												if (
													$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
														.weightVolumeConfigs[l].configurations[n].volumeUnit !=
													undefined
												) {
													basicConfig[count].volumeUnit = $scope.getUnitById(
														$parentScope.entity.shopShippingMethods[i].deliveryServices[j]
															.weightVolumeConfigs[l].configurations[n].volumeUnit.id
													);
												}
												count++;
											}
										}
									}
								}
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.deliveryMethodConfigurations = [];
								$parentScope.entity.shopShippingMethods[i].deliveryServices[
									j
								].deliveryMethod.deliveryMethodConfigurations = basicConfig;
							}
						}
					}
				}
			}

			for (let rule of $parentScope.entity.shippingRules) {
				rule.sortOrder = rule.position;
			}
		};

		$parentScope.$on('afterUpdate', function () {
			$parentScope.entity = ShopService.shippingMethods($parentScope.entity);
			//$scope.weightCountries = $parentScope.entity.weightCountries ;
			//$scope.volumeCountries = $parentScope.entity.volumeCountries ;
		});

		$scope.changeDeliveryMethod = function (deliveryService) {
			NotificationService.notifyWarning({
				statusText: 'If you change the shipping method, previous settings will be removed',
				msg: '',
			});
			if (deliveryService.deliveryMethod.countryConfigurations == undefined) {
				deliveryService.deliveryMethod.countryConfigurations = [];
			}

			if (deliveryService.deliveryMethod.deliveryMethodType != 'DESTINATION') {
				//  deliveryService.deliveryMethod.countryConfigurations = [];
				deliveryService.deliveryMethod.deliveryMethodConfigurations = [];
			}
		};

		$scope.goToPriceRules = function () {
			NotificationService.confirm({
				statusText: 'Haben sie die Änderungen im Shop gespeichert',
				msg: '',
			}).then(
				function () {
					NavigationService.changeState('root.pricerules');
				},
				function () {}
			);
		};

		$scope.validate = () => {
			if ($parentScope.entity.shopConfiguration.companyAddress) {
				$parentScope.entity.street = $parentScope.entity.shopConfiguration.companyAddress.street;
				$parentScope.entity.zipCode = $parentScope.entity.shopConfiguration.companyAddress.zipCode;
				$parentScope.entity.city = $parentScope.entity.shopConfiguration.companyAddress.city;
			}

			if (
				$parentScope.entity.shopShippingMethods &&
				$parentScope.entity.shopShippingMethods[0] &&
				$parentScope.entity.shopShippingMethods[0].tax
			) {
				$parentScope.entity.taxId = $parentScope.entity.shopShippingMethods[0].tax.id;
			}
		};

		$scope.setDefaultShippingMethod = function (shippingMethod) {
			for (let i = 0; i < $parentScope.entity.shopShippingMethods.length; i++) {
				if ($parentScope.entity.shopShippingMethods[i].id == shippingMethod.shopShippingMethod.id) {
					$parentScope.entity.shopShippingMethods[i].isDefault = true;
				} else {
					$parentScope.entity.shopShippingMethods[i].isDefault = false;
				}
			}
		};

		$scope.getShippingMethodById = function (id) {
			for (let i = 0; i < $scope.shippingMethods.length; i++) {
				if ($scope.shippingMethods[i].id == id) {
					return $scope.shippingMethods[i];
				}
			}
		};

		$scope.addShippingMethodToShop = function () {
			$scope.showAddShipping = false;
			$scope.showAddShippingButton = true;

			$parentScope.selectedShippingMethod = $scope.getShippingMethodById(
				$parentScope.data.selectedShippingMethodId
			);

			$parentScope.entity.shopShippingMethods.push({
				id: $parentScope.selectedShippingMethod.id,
				name: $parentScope.selectedShippingMethod.name,
				description: $parentScope.selectedShippingMethod.description,
				deliveryServices: $parentScope.selectedShippingMethod.deliveryServices,
				sortOrder: 99,
				isActive: true,
				isDefault: false,
			});

			$scope.shippingMethodsDropDown = $scope.shippingMethodsDropDown.filter(function (obj) {
				return obj.id !== $parentScope.selectedShippingMethod.id;
			});
		};

		$scope.removeShopShippingMethod = function (index) {
			NotificationService.confirm({
				statusText: 'Are you sure want to delete shipping method from shop?',
				msg: '',
			}).then(
				function () {
					$scope.shippingMethodsDropDown.push(
						$scope.getShippingMethodById($parentScope.entity.shopShippingMethods[index].id)
					);
					$parentScope.entity.shopShippingMethods.splice(index, 1);
				},
				function () {}
			);
		};

		$scope.getUpdatedShippingMethods = () => {
			// debugger;
			ShippingMethodService.findAll().then(function () {
				$scope.shippingMethods = ShippingMethodService.getAll(true);
				$scope.shippingMethodsDropDown = ShippingMethodService.getAll(true);
				for (
					let i = 0;
					$parentScope.entity.shopShippingMethods && i < $parentScope.entity.shopShippingMethods.length;
					i++
				) {
					$scope.shippingMethodsDropDown = $scope.shippingMethodsDropDown.filter(function (obj) {
						return obj.id !== $parentScope.entity.shopShippingMethods[i].id;
					});
				}
			});
		};

		$scope.createShippingMethod = function () {
			if ($scope.newShippingMethod === undefined || $scope.newShippingMethod.name == '') {
				NotificationService.notifyGeneralError({ statusText: 'Empty shipping method name' });
			} else {
				ShippingMethodService.create($scope.newShippingMethod).then(function () {
					$scope.getUpdatedShippingMethods();
					$scope.newShippingMethod = {
						name: '',
						description: '',
					};
					$scope.showCreateShopShipping = false;
				});
			}
		};

		$scope.setSelectedCountries = function () {
			for (let j = 0; j < $scope.countries.length; j++) {
				if ($scope.countries[j].selected) {
					let isAlreadyShopCountry = false;
					for (let i = 0; i < $parentScope.entity.shopCountries.length; i++) {
						if ($parentScope.entity.shopCountries[i].countryId == $scope.countries[j].id) {
							$parentScope.entity.shopCountries[i].isActive = true;
							isAlreadyShopCountry = true;
							break;
						}
					}
					if (!isAlreadyShopCountry) {
						$scope.countries[j].isActive = true;
						$scope.countries[j].countryId = $scope.countries[j].id;
						$parentScope.entity.shopCountries.push($scope.countries[j]);
					}
				} else {
					for (
						let i = 0;
						$parentScope.entity.shopCountries && i < $parentScope.entity.shopCountries.length;
						i++
					) {
						if ($parentScope.entity.shopCountries[i].countryId == $scope.countries[j].id) {
							$parentScope.entity.shopCountries.splice(i, 1);
						}
					}
				}
			}
		};

		$scope.addCountry = function (configurations, countryId) {
			if (countryId == undefined) {
				return;
			}
			for (let i = 0; i < configurations.length; i++) {
				if (configurations[i].country.id == countryId) {
					return;
				}
			}
			configurations.push({
				value: 0,
				surcharge: 0,
				country: $scope.getCountryById(countryId),
			});
		};

		$scope.deleteCountry = function (configurations, index) {
			configurations.splice(index, 1);
		};

		$scope.countriesSetPage = function () {
			CountryService.setCurrentPage($scope.getRealCurrentPage($parentScope.selected.countriesCurrentPage));
		};

		$scope.setTableDataCountries = function () {
			$parentScope.selected.countriesPageSize = CountryService.getPageSize();
			$parentScope.selected.countriesTotalItems = CountryService.getTotalItems();

			$scope.countriesTotalPages = CountryService.getTotalPages();

			if ($scope.countriesTotalPages === 0) {
				//If there are no items the total pages size should be displayed as 1
				$scope.countriesTotalPages = 1;
			}

			$parentScope.selected.countriesCurrentPage = CountryService.getCurrentPage();
			$scope.countries = CountryService.getAll();
			$parentScope.$broadcast('tableDataRefreshed');
		};

		$scope.markSelectedCountries = function () {
			for (let key in $scope.countries) {
				if ($parentScope.entity.shopCountries != undefined) {
					for (let i = 0; i < $parentScope.entity.shopCountries.length; i++) {
						if ($parentScope.entity.shopCountries[i].countryId == $scope.countries[key].id) {
							$scope.countries[key].selected = true;
						}
					}
				}
			}
		};

		UnitService.findAll().then(function () {
			$scope.units = UnitService.getAll(true);
			$scope.weightUnits = UnitService.getUnitsByType('WEIGHT');
			$scope.volumeUnits = UnitService.getUnitsByType('VOLUME');
		});

		$scope.addWeightCountries = function (service) {
			let alreadyPresent = [];
			if (service.weightConfigs == undefined) {
				service.weightConfigs = [];
			}
			for (let i = 0; i < $parentScope.data.tmpweightCountries.length; i++) {
				for (let j = 0; j < service.weightConfigs.length; j++) {
					for (let k = 0; k < service.weightConfigs[j].countries.length; k++) {
						if (service.weightConfigs[j].countries[k] == $parentScope.data.tmpweightCountries[i]) {
							alreadyPresent.push(i);
						}
					}
				}
			}
			if (alreadyPresent.length > 0) {
				let i = alreadyPresent.length;
				while (i--) {
					$parentScope.data.tmpweightCountries.splice(alreadyPresent[i], 1);
				}
			}

			if ($parentScope.data.tmpweightCountries.length > 0) {
				let weightConf = {};
				let countries = [];
				let configs = [];

				let groupId = 1;
				if (service.weightConfigs.length > 0) {
					groupId = Math.max.apply(
						Math,
						service.weightConfigs.map(function (o) {
							return o.groupId;
						})
					);
				}
				weightConf['groupId'] = groupId + 1;

				countries = $parentScope.data.tmpweightCountries;
				weightConf['countries'] = countries;
				configs.push({
					value: 0,
					surcharge: 0,
					weight: 0,
					weightUnitId: 0,
				});

				weightConf['configurations'] = configs;
				service.weightConfigs.push(weightConf);
			} else {
				return;
			}

			//service.countries[service.weightCountries.length] = $parentScope.data.tmpweightCountries;
			let i = Object.keys($scope.selectCountries).length;
			while (i--) {
				for (let m = 0; m < $parentScope.data.tmpweightCountries.length; m++) {
					if ($scope.selectCountries[i].id == $parentScope.data.tmpweightCountries[m]) {
						//delete $scope.selectCountries[i];
					}
				}
			}

			$parentScope.data.tmpweightCountries = [];
		};

		$scope.addWeight = function (config) {
			config.push({
				value: 0,
				surcharge: 0,
				weight: 0,
				weightUnitId: 0,
			});
		};

		$scope.deleteWeightCountry = function (config, index, configs) {
			config.configurations.splice(index, 1);
			if (config.configurations.length == 0) {
				let grpIndex = configs
					.map(function (o) {
						return o.groupId;
					})
					.indexOf(config.groupId);

				configs.splice(grpIndex, 1);
			}
			// deliveryService.weightCountries.splice(index,1);
		};

		$scope.getUnitById = function (id) {
			for (let i = 0; i < $scope.units.length; i++) {
				if ($scope.units[i].id == id) {
					return $scope.units[i];
				}
			}
		};

		$scope.getCountryById = function (id) {
			for (let i = 0; i < $scope.allCountries.length; i++) {
				if ($scope.allCountries[i].id == id) {
					return $scope.allCountries[i];
				}
			}
		};

		// volume

		$scope.addVolumeCountries = function (service) {
			let alreadyPresent = [];
			if (service.volumeConfigs == undefined) {
				service.volumeConfigs = [];
			}
			for (let i = 0; i < $parentScope.data.tmpvolumeCountries.length; i++) {
				for (let j = 0; j < service.volumeConfigs.length; j++) {
					for (let k = 0; k < service.volumeConfigs[j].countries.length; k++) {
						if (service.volumeConfigs[j].countries[k] == $parentScope.data.tmpvolumeCountries[i]) {
							alreadyPresent.push(i);
						}
					}
				}
			}
			if (alreadyPresent.length > 0) {
				let i = alreadyPresent.length;
				while (i--) {
					$parentScope.data.tmpvolumeCountries.splice(alreadyPresent[i], 1);
				}
			}

			if ($parentScope.data.tmpvolumeCountries.length > 0) {
				let volumeConf = {};
				let countries = [];
				let configs = [];

				let groupId = 1;
				if (service.volumeConfigs.length > 0) {
					groupId = Math.max.apply(
						Math,
						service.volumeConfigs.map(function (o) {
							return o.groupId;
						})
					);
				}
				volumeConf['groupId'] = groupId + 1;

				countries = $parentScope.data.tmpvolumeCountries;
				volumeConf['countries'] = countries;
				configs.push({
					value: 0,
					surcharge: 0,
					volume: 0,
					width: 0,
					height: 0,
					depth: 0,
					volumeUnitId: 0,
				});

				volumeConf['configurations'] = configs;
				service.volumeConfigs.push(volumeConf);
			} else {
				return;
			}

			let i = Object.keys($scope.selectCountries).length;
			while (i--) {
				for (let m = 0; m < $parentScope.data.tmpvolumeCountries.length; m++) {
					if ($scope.selectCountries[i].id == $parentScope.data.tmpvolumeCountries[m]) {
						//  delete $scope.selectCountries[i];
					}
				}
			}

			$parentScope.data.tmpvolumeCountries = [];
		};

		$scope.addvolume = function (configurations) {
			configurations.push({
				value: 0,
				surcharge: 0,
				volume: 0,
				width: 0,
				height: 0,
				depth: 0,
				volumeUnitId: 0,
			});
		};

		$scope.deleteVolumeCountry = function (config, index, configs) {
			config.configurations.splice(index, 1);
			if (config.configurations.length == 0) {
				let grpIndex = configs
					.map(function (o) {
						return o.groupId;
					})
					.indexOf(config.groupId);

				configs.splice(grpIndex, 1);
			}
			// deliveryService.weightCountries.splice(index,1);
		};

		$scope.addWeightVolumeCountries = function (service) {
			let alreadyPresent = [];
			if (service.weightVolumeConfigs == undefined) {
				service.weightVolumeConfigs = [];
			}
			for (let i = 0; i < $parentScope.data.tmpweightVolumeCountries.length; i++) {
				for (let j = 0; j < service.weightVolumeConfigs.length; j++) {
					for (let k = 0; k < service.weightVolumeConfigs[j].countries.length; k++) {
						if (
							service.weightVolumeConfigs[j].countries[k] == $parentScope.data.tmpweightVolumeCountries[i]
						) {
							alreadyPresent.push(i);
						}
					}
				}
			}
			if (alreadyPresent.length > 0) {
				let i = alreadyPresent.length;
				while (i--) {
					$parentScope.data.tmpweightVolumeCountries.splice(alreadyPresent[i], 1);
				}
			}

			if ($parentScope.data.tmpweightVolumeCountries.length > 0) {
				let weightVolumeConf = {};
				let countries = [];
				let configs = [];

				let groupId = 1;
				if (service.weightVolumeConfigs.length > 0) {
					groupId = Math.max.apply(
						Math,
						service.weightVolumeConfigs.map(function (o) {
							return o.groupId;
						})
					);
				}
				weightVolumeConf['groupId'] = groupId + 1;

				countries = $parentScope.data.tmpweightVolumeCountries;
				weightVolumeConf['countries'] = countries;
				configs.push({
					value: 0,
					surcharge: 0,
					weight: 0,
					weightUnitId: 0,
					volume: 0,
					width: 0,
					height: 0,
					depth: 0,
					volumeUnitId: 0,
				});

				weightVolumeConf['configurations'] = configs;
				service.weightVolumeConfigs.push(weightVolumeConf);
			} else {
				return;
			}

			let i = Object.keys($scope.selectCountries).length;
			while (i--) {
				for (let m = 0; m < $parentScope.data.tmpweightVolumeCountries.length; m++) {
					if ($scope.selectCountries[i].id == $parentScope.data.tmpweightVolumeCountries[m]) {
						//  delete $scope.selectCountries[i];
					}
				}
			}

			$parentScope.data.tmpweightVolumeCountries = [];
		};

		$scope.addWeightVolume = function (configurations) {
			configurations.push({
				value: 0,
				surcharge: 0,
				weight: 0,
				weightUnitId: 0,
				volume: 0,
				width: 0,
				height: 0,
				depth: 0,
				volumeUnitId: 0,
			});
		};

		$scope.deleteWeightVolumeCountry = function (config, index, configs) {
			config.configurations.splice(index, 1);
			if (config.configurations.length == 0) {
				let grpIndex = configs
					.map(function (o) {
						return o.groupId;
					})
					.indexOf(config.groupId);

				configs.splice(grpIndex, 1);
			}
			// deliveryService.weightCountries.splice(index,1);
		};

		$scope.getUnitfactor = function (parentIndex, index) {
			if (
				$parentScope.entity.shippingRules[parentIndex].ruleConditions &&
				$parentScope.entity.shippingRules[parentIndex].ruleConditions[index].unitId != undefined
			) {
				let unit = $scope.shippingMethodScope.getUnitById(
					$parentScope.entity.shippingRules[parentIndex].ruleConditions[index].unitId
				);
				if (unit) {
					$parentScope.entity.shippingRules[parentIndex].ruleConditions[index].factor = unit.factor;
				}
			}
		};

		// Shipping Rules

		$scope.groupConjunctions = RuleService.groupConjunctions();
		$scope.conditionConjunctions = RuleService.conditionConjunctions();
		$scope.shippingConfigToAdd = {};
		RuleService.conditionTypes().then((conditions) => {
			$scope.conditionTypes = conditions;
		});

		$scope.sortableOptions = {
			'ui-floating': true,
			axis: 'y',
			stop: function (e, ui) {
				for (let i = 0; i < $parentScope.entity.shippingRules.length; i++) {
					$parentScope.entity.shippingRules[i].position = i + 1;
				}
			},
		};

		$scope.addShippingMethodConfig = function (i) {
			if ($scope.shippingConfigToAdd.id === undefined) {
				return;
			}
			const shippingMethod = $parentScope.entity.shopShippingMethods.find(
				(shipping) => shipping.id === $scope.shippingConfigToAdd.id && !!shipping.id
			);
			$scope.shippingConfigToAdd.id = undefined;

			if ($parentScope.entity.shippingRules[i].shopShippingMethods === undefined) {
				$parentScope.entity.shippingRules[i].shopShippingMethods = [];
			}
			$parentScope.entity.shippingRules[i].shopShippingMethods.push(shippingMethod);
		};

		$scope.addCondition = function (conditionGroup) {
			if (conditionGroup.conditions === undefined) {
				conditionGroup.conditions = [];
			}
			conditionGroup.conditions.push({});
		};

		$scope.addConditionGroup = function (policy) {
			if (policy.conditionGroups === undefined) {
				policy.conditionGroups = [];
			}
			policy.conditionGroups.push({ conditionEvaluationType: 'ALL_TRUE' });
		};

		$scope.addPolicy = function () {
			if ($parentScope.entity.shippingRules === undefined) {
				$parentScope.entity.shippingRules = [];
			}
			let highestPosition =
				$parentScope.entity.shippingRules.length > 0
					? $parentScope.entity.shippingRules.sort((lh, rh) => lh.position - rh.position).position + 1
					: 0;

			$parentScope.entity.shippingRules.push({
				name: '',
				conjunction: 'AND',
				active: true,
				position: highestPosition,
			});
		};

		$scope.deleteShippingMethodPolicy = function (policy, index) {
			policy.shopShippingMethods.splice(index, 1);
		};

		$scope.deleteCondition = function (conditionGroup, index) {
			conditionGroup.conditions.splice(index, 1);
		};

		$scope.deleteConditionGroup = function (policy, index) {
			policy.conditionGroups.splice(index, 1);
		};

		$scope.deleteShopShippingPolicy = function (index) {
			$parentScope.entity.shippingRules.splice(index, 1);
		};

		$scope.onSetConditionType = function (condition) {
			for (let key in condition.conditionDescriptor) {
				if (key !== 'className') {
					delete condition.conditionDescriptor[key];
				}
			}
		};

		// Specifically used by condition descriptors that need metadata. Move it when refactoring conditions to each have their own directive
		$scope.metadataValues = {};
		$scope.conditionMetadataSelect = function (metadataNameId) {
			if (metadataNameId !== undefined) {
				MetadataService.findValues(metadataNameId).then(function (res) {
					$scope.metadataValues[metadataNameId] = res;
				});
			}
		};
	};
});

