define('modules/settings/shop/tabs/PluginSettingsTabController',[], function () {
	'use strict';

	return function PluginSettingsController($parentScope, $scope, { BASE_TEMPLATES_PATH, ShopService }) {
		$parentScope.formTabs.push({
			label: 'FIELDS.PLUGIN_SETTINGS',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-plugin-settings.html',
		});

		$scope.init = () => {
			if ($parentScope.entity.pluginSettings === undefined) {
				$parentScope.entity.pluginSettings = []; // TODO init from shop entity
			}

			ShopService.fetchPluginSettingTemplates($parentScope.entity.id).then(() => {
				$scope.pluginSettingTemplates = ShopService.pluginSettingTemplates();

				$scope.pluginSettings = {};
				// Prepare plugin setting defaults
				for (let plugin of $scope.pluginSettingTemplates) {
					for (let setting of plugin.settingTemplates) {
						$scope.pluginSettings[setting.scopeId] = $scope.pluginSettings[setting.scopeId] || {};
						$scope.pluginSettings[setting.scopeId][setting.settingCategory.name] =
							$scope.pluginSettings[setting.scopeId][setting.settingCategory.name] || {};

						$scope.pluginSettings[setting.scopeId][setting.settingCategory.name][setting.key] = {
							value: setting.dataType !== 'PASSWORD' ? setting.defaultValue : undefined,
							dataType: setting.dataType,
							definitionSpace: setting.definitionSpace,
							label: setting.label,
						};
					}
				}
			});
		};

		$scope.validate = function () {
			// TODO
		};

		$scope.packPluginSettings = function () {
			// Package plugin setting
			for (let pluginId in $scope.pluginSettings) {
				let pack = { plugin: pluginId };
				let categories = [];

				for (let categoryName in $scope.pluginSettings[pluginId]) {
					let category = { name: categoryName };
					let settings = [];

					for (let settingId in $scope.pluginSettings[pluginId][categoryName]) {
						const setting = $scope.pluginSettings[pluginId][categoryName][settingId];

						let persistedValue = setting.value;
						// Clear password inputs after persisting
						if (setting.dataType === 'PASSWORD') {
							setting.value = undefined;
						}

						settings.push({
							key: settingId,
							value: persistedValue,
							label: setting.label,
							dataType: setting.dataType,
							definitionSpace: setting.definitionSpace,
						});
					}
					category.settings = settings;
					categories.push(category);
				}
				pack.categories = categories;
				$parentScope.entity.pluginSettings = [];
				$parentScope.entity.pluginSettings.push(pack);
			}
		};
	};
});

