define('directives/controls/customSelect/CustomSelect',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngCustomSelect', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					remote: '=?',
					onSearch: '=?',
					label: '@',
					outerLabel: '=?',
					options: '=',
					display: '@',
					value: '@',
					change: '=',
					customChange: '&',
					result: '=',
					placeholder: '@',
					styleClass: '@',
					disable: '=',
					id: '=',
					customColor: '=',
					mandatory: '@',
					hideSearch: '@',
					useLocalSearch: '=?',
					avoidSortValues: '=?',
					noSort: '=?'
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/controls/customSelect/views/CustomSelect.html',
				controller: [
					'$scope',
					function ($scope) {
						$scope.$watch('result', function (newValue, oldValue) {
							if (newValue === undefined || newValue === oldValue) return;

							if ($scope.change !== undefined) $scope.change($scope.result);
							if ($scope.customChange !== undefined) $scope.customChange();
						});

						$scope.searchTerm;
						$scope.clearSearchTerm = function () {
							$scope.searchTerm = '';
						};

						$scope.onSearchChange = function ($event, searchTerm) {
							if (!!$scope.onSearch) {
								$scope.onSearch($event, searchTerm);
							} else {
								$scope.localSearch(searchTerm);
							}
						};

						$scope.localSearch = function (searchTerm) {
							if (searchTerm !== '') {
								const regex = new RegExp('.*' + searchTerm.toLowerCase() + '.*');
								$scope.diplayedOptions = $scope.options.filter((o) => {
									const value = (o.value || o.name || '').toLowerCase();
									return regex.test(value);
								});
							} else {
								$scope.diplayedOptions = Array.isArray($scope.options) ? [...$scope.options] : [];
							}
						};

						$scope.onKeydown = function ($event) {
							$event.stopPropagation();
						};

						if ($scope.display === undefined) {
							$scope.display = 'display';
						}

						$scope.$watch('options', function (newValue, oldValue) {
							if ($scope.options !== undefined && !$scope.avoidSortValues && $scope.options.sort) {
								$scope.diplayedOptions = [...$scope.options];

								if (!$scope.noSort) {
									$scope.diplayedOptions.sort(function(a, b) {
										if (!a[$scope.display] || !b[$scope.display]) {
											return;
										}
										var textA = a[$scope.display].toUpperCase();
										var textB = b[$scope.display].toUpperCase();
										return textA < textB ? -1 : textA > textB ? 1 : 0;
									});
								}
							} else {
								$scope.diplayedOptions = Array.isArray($scope.options) ? [...$scope.options] : [];
							}
						});

						if ($scope.value === undefined) {
							$scope.value = 'value';
						}

						if ($scope.outerLabel === undefined) {
							$scope.outerLabel = false;
						}

						$scope.getOptionDisplayOrValue = (option, displayPath, valuePath) => {
							const displayValue = $scope.getValue(option, displayPath);

							if (displayValue === undefined) {
								return $scope.getValue(option, valuePath);
							}

							return displayValue;
						};

						$scope.getValue = function (option, path) {
							var dp = path.split('.');

							var value = option;
							if (path !== '.') {
								for (var i = 0; i < dp.length; i++) {
									value = value[dp[i]];
								}
							}

							return value;
						};
					},
				],
			};
		},
	]);
});

