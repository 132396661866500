define('modules/wawi/product/controllers/tabs/GeneralTabController',[], function () {
	'use strict';

	return (
		$parentScope,
		$scope,
		{
			BASE_TEMPLATES_PATH,
			PublishStateService,
			UnitUnpagedService,
			TaxService,
			ManufacturerService,
			CustomerGroupService,
		}
	) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-main.html',
			active: true,
		});

		$scope.sortTypes = [
			{
				name: 'Manuelle Sortierung',
				value: 'sortOrder ASC',
				available: true,
			},
			{
				name: 'Name aufsteigend',
				value: 'name.raw ASC',
				available: true,
			},
			{ name: 'Name absteigend', value: 'name.raw DESC', available: true },
			{ name: 'Preis aufsteigend', value: 'sellingPrice ASC', available: true },
			{ name: 'Preis absteigend', value: 'sellingPrice DESC', available: true },
		];

		PublishStateService.findAll().then(function () {
			$scope.publishStates = PublishStateService.getAll(true);
		});

		UnitUnpagedService.findAll().then(function () {
			$scope.units = UnitUnpagedService.getAll(true).data;

			$scope.weightUnits = [];
			for (var i = 0; i < $scope.units.length; i++) {
				var unit = $scope.units[i];
				if (unit.type == 'WEIGHT' || unit.type == 'WEIGHT_CALCULATED') {
					$scope.weightUnits.push(unit);
				}
			}

			$scope.volumeUnits = [];
			for (var i = 0; i < $scope.units.length; i++) {
				var unit = $scope.units[i];
				if (unit.type == 'VOLUME') {
					$scope.volumeUnits.push(unit);
				}
			}
		});

		TaxService.findAll(true).then(function () {
			$scope.taxes = TaxService.getAll();
		});

		ManufacturerService.findAll(true).then(function () {
			$scope.manufacturers = ManufacturerService.getAll();
		});

		CustomerGroupService.findAll(true).then(function () {
			$scope.customerGroups = CustomerGroupService.getAll();
		});

		$scope.tinymceOptions = {
			theme: 'modern',
			height: '500',
			convert_urls: false,
			relative_urls: false,
			remove_script_host: false,
			plugins: [
				'link advlist lists image charmap print preview hr anchor pagebreak',
				'searchreplace wordcount visualblocks visualchars code fullscreen',
				'insertdatetime media nonbreaking save table contextmenu directionality',
				'emoticons template paste textcolor colorpicker textpattern imagetools',
				'fullpage moodiaimage',
			],
			menubar: 'insert | tools',
			toolbar1:
				'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
			toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code ',
			image_advtab: true,
		};

		$scope.bundleTableOptions = {};
		$scope.bundleTableOptions.fields = [
			{
				id: 'ORDER',
				heading: 'FIELDS.SORT_ORDER',
				attribute: 'sortOrder',
			},
			{
				id: 'NAME',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				id: 'PRICE',
				heading: 'FIELDS.PURCHASE_PRICE',
				attribute: 'basePrice',
			},
			{
				id: 'SKU',
				heading: 'FIELDS.SKU',
				attribute: 'sku',
			},
			{
				id: 'AMOUNT',
				heading: 'FIELDS.AMOUNT',
				attribute: 'amount',
				filterDisabled: true,
			},
		];
		$scope.bundleTableOptions.name = 'WAWI.PRODUCT.BUNDLE_PRODUCT';
	};
});

