define('directives/controls/customInput/CustomInput',[
	'app',
	'settings',
	'directives/components/common/transformInput/TransformInput',
	'directives/components/common/restrict/Restrict',
	'directives/infinity/Infinity',
	'filters/GenderFilter',
], function (app) {
	'use strict';
	app.directive('ngCustomInput', [
		'$timeout',
		'$window',
		'BASE_DIRECTIVES_PATH',
		function ($timeout, $window, BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					//label: "@",
					inputId: '@',
					outerLabelClass: '@',
					innerLabelClass: '@',
					outerLabel: '=',
					label: '@',
					type: '@',
					datatype: '@',
					placeholder: '@',
					styleClass: '@',
					containerClass: '@',
					change: '=', // two-way binding, is executed manually on result change
					changeFunction: '&', // function binding, is passed directly to input element
					maxlength: '=',
					step: '=',
					result: '=',
					restrict: '@',
					disable: '=',
					focus: '=',
					selectOnClick: '=',
					customColor: '=',
					mandatory: '@',
					defaultValue: '=?',
					min: '=?',
					useInfinity: '=?'
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/controls/customInput/views/CustomInput.html',
				link: function (scope, element) {
					scope.$watch('result', function (newValue, oldValue) {
						if (newValue === undefined || newValue == oldValue) return;

						if (scope.change !== undefined) scope.change(scope.result);
					});

					scope.$watch('focus', function (newValue) {
						if (newValue == true) {
							scope.focus = false;
							$timeout(function () {
								jQuery(element).find('input').focus();
							});
						}
					});
					element.on('click', function () {
						if (!$window.getSelection().toString()) {
							if (scope.selectOnClick) {
								var textbox = jQuery(element).find('input')[0];
								textbox.setSelectionRange(0, textbox.value.length);
							}
						}
					});
				},
				controller: ['$scope', function ($scope) {}],
			};
		},
	]);
});

