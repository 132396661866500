define('services/UserService',[
	'app',
	'modules/module',
	'services/NotificationService',
	'services/LocalStorageService',
	'services/ShopService',
], function (app, module) {
	'use strict';
	return module.factory('UserService', [
		'$q',
		'$rootScope',
		'$state',
		'$cookies',
		'jwtHelper',
		'ModelFactory',
		'NotificationService',
		'LocalStorageService',
		'ShopService',
		function (
			$q,
			$rootScope,
			$state,
			$cookies,
			jwtHelper,
			ModelFactory,
			NotificationService,
			LocalStorageService,
			ShopService
		) {
			const ROLE_ADMIN = 'ROLE_ADMIN';
			const ROLE_ECONNECT = 'ROLE_ECONNECT';
			const ROLE_SUPERUSER = 'ROLE_SUPERUSER';

			var constantCheckInteval = undefined;

			var badUsername = undefined;

			var checkUserStateConstantly = function () {
				constantCheckInteval = setTimeout(checkUserState, 10000 * 1000);
			};

			var checkUserState = function () {
				var ref = $q.defer();
				ModelFactory.User.status().$promise.then(function (response) {
					setUser(response);
				});
				return ref.promise;
			};

			var setUser = function (user) {
				$cookies.putObject('_user', user);

				if ($cookies.getObject('_user') === undefined && !!user) {
					// user to large for cookies
					LocalStorageService.write('_user', user);
				}

				if (constantCheckInteval !== undefined) {
					clearTimeout(constantCheckInteval);
				}
				if (user !== undefined) {
					checkUserStateConstantly();
				}
				$rootScope.$emit('userStatusChanged', user);
			};

			var setToken = function (token) {
				LocalStorageService.write('_token', token);
			};

			var setShop = function (shopId) {
				LocalStorageService.write('_shop', shopId);
			};

			var setLocale = function (localeId) {
				LocalStorageService.write('_locale', localeId);
			};

			var setShopAndLocale = function () {
				var deferred = $q.defer();

				getFirstShopFromUserRoles().then((shop) => {
					setShop(shop.id);
					setLocale(shop.defaultLocaleId);

					deferred.resolve();
				});
				return deferred.promise;
			};

			const decodeJwtTokenAndReturnData = (token) => {
				return jwtHelper.decodeToken(token).data;
			};

			/**
			 * Is user has ROLE_SUPERUSER, function returns any shop id.
			 * @returns promise
			 */
			const getFirstShopFromUserRoles = () => {
				const deferred = $q.defer();
				const user = getUser();

				if (isSuperuser()) {
					ShopService.findAnyShop().then((shop) => {
						deferred.resolve(shop);
					});
				} else {
					if (!!user.roles.length) {
						if (Array.isArray(user.roles[0].shopIds) && !!user.roles[0].shopIds.length) {
							ShopService.findOne(user.roles[0].shopIds[0]).then(() => {
								deferred.resolve(ShopService.getOne());
							});
						}
					}
				}

				return deferred.promise;
			};

			const getCurrentShopId = () => {
				//TODO: don't hardcode the key
				return LocalStorageService.read('_shop');
			};

			const getUser = () => {
				return $cookies.getObject('_user') || LocalStorageService.read('_user');
			};

			const getUserId = () => {
				const user = getUser();

				if (!user) {
					return null;
				}

				return user.id;
			};

			const getUserRoles = () => {
				const user = getUser();

				if (!user) {
					return [];
				}

				return user.roles;
			};

			const hasRoleAdmin = (shopId) => {
				// If shopId wasn't passed, check permission for the current shop
				if (shopId === undefined) {
					shopId = getCurrentShopId();
				}
				return getUserRoles().some(
					(role) => role.name === ROLE_SUPERUSER || (role.name === ROLE_ADMIN && Array.isArray(role.shopIds) && role.shopIds.includes(shopId))
				);
			};

			const isEconnectUser = () => {
				return getUserRoles().some((role) => role.name === ROLE_ECONNECT || role.name === ROLE_SUPERUSER);
			};

			const isSuperuser = () => {
				return getUserRoles().some((role) => role.name === ROLE_SUPERUSER);
			};

			return {
				ROLE_SUPERUSER: ROLE_SUPERUSER,
				doesUserHaveARole: (roleName) => {
					if (roleName === ROLE_SUPERUSER) {
						return isSuperuser();
					} else if (roleName === ROLE_ADMIN) {
						return hasRoleAdmin();
					} else if (roleName === ROLE_ECONNECT) {
						return isEconnectUser();
					}

					return false;
				},
				hasRoleAdmin: hasRoleAdmin,
				isEconnectOnlyUser: () => {
					const roles = getUserRoles();
					if (roles.length === 0) {
						return false;
					}

					for (const role of roles) {
						if (role.name !== ROLE_ECONNECT) {
							return false;
						}
					}

					return true;
				},
				isEconnectUser: isEconnectUser,
				isLoggedIn: function () {
					return LocalStorageService.read('_token') !== null && LocalStorageService.read('_token') !== false;
				},
				isSuperuser: isSuperuser,
				getAllowedShopIds: () => {
					return getUser().roles.map((role) => role.shopId);
				},
				getToken: function () {
					return LocalStorageService.read('_token');
				},
				isBadUsername: function () {
					return badUsername;
				},
				removeLogin: function () {
					$cookies.remove('_user');
					LocalStorageService.remove('_user');
				},
				getUser: getUser,
				getUserId: getUserId,
				loadUser: checkUserState,
				login: function (user) {
					const ref = $q.defer();
					ModelFactory.User()
						.login(user)
						.$promise.then(
							(response) => {
								if (response.token != undefined) {
									setUser(decodeJwtTokenAndReturnData(response.token));
									setToken(response.token);
									setShopAndLocale().then(() => {
										ref.resolve();
									});
								} else {
									setToken(false);
									ref.reject();
								}
							},
							() => {
								ref.reject();
							}
						);
					return ref.promise;
				},
				logout: function () {
					LocalStorageService.remove('_token');
					LocalStorageService.remove('_user');
					LocalStorageService.remove('_locale');
					LocalStorageService.remove('_shop');
					$cookies.remove('_user');
				},
				sendPasswordReset: function (emailOrUsername) {
					badUsername = undefined;
					ModelFactory.User()
						.passwordReset({ emailOrUsername: emailOrUsername })
						.$promise.then(function (response) {
							if (response.ok) {
								NotificationService.notifySuccess({
									statusText: 'Password Reset Email wurde versandt.',
								});
								badUsername = false;
							} else {
								badUsername = true;
							}
						}, NotificationService.notifyRequestError);
				},
				sendPasswortResetActivation: function (resetKey, newPassword) {
					ModelFactory.User()
						.passwordResetActivation({ resetKey: resetKey, newPassword: newPassword })
						.$promise.then(function (response) {
							if (response.token) {
								NotificationService.notifySuccess({ statusText: 'Password wurde geändert.' });
								$state.go('root.login');
							}
							return response;
						}, NotificationService.notifyRequestError);
				},
				setLocale: function (localeId) {
					setLocale(localeId);
				},
				setShop: function (shopId) {
					setShop(shopId);
				},
				getShop: getCurrentShopId,
			};
		},
	]);
});

